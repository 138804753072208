* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  @media only screen and (max-width: 500px) {
    font-size: 10px;
  }
}

.App {
  font-family: sans-serif;
  text-align: center;
}
// canvas {
//   width: 100vw;
//   height: 100vh;
// }

body {
  padding: 0;
  margin: 0;
  font-family: "Oswald", sans-serif;
  color: white;
  background: #040105;
}

#root {
  height: 100vh;
}

input,
select {
  font-family: "Oswald", sans-serif;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#menu {  
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  padding: 1.6rem;
  z-index: 10000;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: right;

  &.open {
    background-color: rgba(20,20,60,0.8);
  }

  input, select { width: 100%;
    @media only screen and (max-width: 500px) {
      font-size: 2rem;

    }
  }


  @media only screen and (max-width: 500px) {
    font-size: 1rem;
    bottom: auto;
    top: 0;
    right:0;
    width: 100%;
  }

  button {
    grid-row: 0;
  }

}

.grid {
  display: grid;
}

#date {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  bottom: 3rem;
  left: 3rem;
  line-height: 3rem;
  h1 {
    font-weight: bold;
    font-size: 4em;
    margin:0;
    font-weight: 200;
    line-height: 4.1rem;
  }
  h2 { 
    margin: 0; 
    font-variation-settings: 'wdth' 100, 'wght' 98;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-left:0.2rem;
  }
}

#dashboard {
  width: 100%;
  position: absolute;
  top:0;left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  padding: 3rem;
  z-index: 1000;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr ;
  }

}



.data {
 margin-bottom: 1rem;
 display: flex;
 flex-direction: column-reverse;
 text-align: center;
 @media only screen and (max-width: 500px) {
  text-align: left;
}
 h2 {
   margin: 0;
   font-size: 1rem;
   text-transform: uppercase;
   font-weight: 200;
   letter-spacing: 0.2rem;
   line-height: 1.2rem;
  
  }
  .value {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  
    div {display:inline-block; transition: 0.5;}
  }

}

.icon-button {
  cursor: pointer;
  background: none;
  border: 0;
  font-size: 2rem;
}

.chroma {
  text-shadow: -0.15rem 0px 0.15rem rgba(200, 0, 0, 0.8), 0.15rem 0px 0.15rem rgba(0, 0, 200, 0.8);
}

.toggle {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: rgba(255,255,255,0.8);
  padding: .5rem;
  font-size: 1rem;
  @media only screen and (max-width: 500px) {
    font-size: 2rem;

  }
}

.on {
  color: greenyellow;
}

a {
  color: rgba(20,225,225,0.8)
}

